<template>
  <div class="home-container">
    <GuruBreadcrumb :link-list="linkList"/>
    <div class="has-text-centered guru-ins-title" v-if="currentAssociation.institute">JDoodle Guru - {{currentAssociation.institute.name}}</div>
    <div v-if="isActive">
      <div v-if="isStudent" class="main-section">
        <AssignmentsAsStudent/>
      </div>
      <div class="has-text-centered" v-if="isAccountOwner || isTeacher || isInstituteAdmin">
        <div class="has-text-centered guru-link box" v-if="isAccountOwner || isTeacher"><router-link to="assignments" append><font-awesome-icon icon="pencil-alt"/>&nbsp;&nbsp;&nbsp;Assignments</router-link></div>
        <div class="has-text-centered guru-link box"><router-link to="sgs" append><font-awesome-icon icon="users"/>&nbsp;&nbsp;&nbsp;Student Groups</router-link></div>
        <div class="has-text-centered guru-link box"><router-link to="users" append><font-awesome-icon icon="user-tie"/>&nbsp;&nbsp;&nbsp;User Management</router-link></div>
        <!--<div class="has-text-centered guru-link box"><router-link to="assignments" append><font-awesome-icon icon="university"/>&nbsp;&nbsp;&nbsp;Institute Library</router-link></div>-->
        <!--<div class="has-text-centered guru-link box"><router-link to="assignments" append><font-awesome-icon icon="globe"/>&nbsp;&nbsp;&nbsp;Public Library</router-link></div>-->
        <div class="has-text-centered guru-link box"><a @click="openInsDetails"><font-awesome-icon icon="cog"/>&nbsp;&nbsp;&nbsp;Institute Details</a></div>
      </div>
    </div>
    <div v-else-if="isPendingAcceptance || isRejected">
      <DoodleGeneralForm in-progress-message="Creating Virtual Institute, Please wait..."
                         success-message="Virtual Institute Created."
                         :form="doodleForm"
                         :inProgress="doodleForm.inProgress" :completed="doodleForm.completed"
                         :errorMessageText="doodleForm.errorMessage" hide-action-close="true" @close="gotoGuruHome">
        <h5 class="has-text-centered">
          {{currentAssociation.institute.name}} has added you into their institute as {{currentAssociation.associationTypes}} .<br/>
          <span v-show="isRejected">Earlier you rejected this invite, </span> would you like to accept <span v-show="isRejected">now</span>?
        </h5>
        <div class="has-text-centered margin-top-20px">
          <button class="button is-success has-text-weight-semibold" type="button" v-on:click="responseToInvite('accept')">
            Accept
          </button>&nbsp;&nbsp;&nbsp;
          <button class="button is-danger has-text-weight-semibold" type="button" v-on:click="responseToInvite('reject')">
            Reject
          </button>
        </div>
        <div class="has-text-centered margin-top-10px">Please report unauthorized invites to hello@jdoodle.com</div>
      </DoodleGeneralForm>
    </div>
    <div v-else-if="isInActive">
      <div class="has-text-centered margin-top-10px">Your association with this Institute is 'Inactive'. <br/> Please contact the institute to activate your association with this institute.</div>
    </div>
    <div v-else-if="isExited">

    </div>
  </div>
</template>

<script>
import formMixin from '../../assets/javascript/form-mixin'
import GuruBreadcrumb from './GuruBreadcrumb'
import { eventBus } from '../../assets/javascript/event-bus'
import { GURU_EVENTS, GURU_USER_STATUS } from '../../assets/javascript/constants'
import DoodleGeneralForm from '../Utils/DoodleGeneralForm'
import AssignmentsAsStudent from './Assignment/GuruAssignmentsAsStudent'
export default {
  name: 'GuruInstitute',
  mixins: [formMixin],
  components: { AssignmentsAsStudent, DoodleGeneralForm, GuruBreadcrumb },
  props: ['currentAssociation', 'isAccountOwner', 'isInstituteAdmin', 'isTeacher', 'isStudent'],
  inject: ['$validator'],
  data: function () {
    return {
      linkList: [
        {
          title: 'Guru Home',
          href: '/guru'
        }
      ]
    }
  },
  methods: {
    openInsDetails () {
      eventBus.$emit(GURU_EVENTS.SHOW_IN_SIDEBAR, () => import('./GuruRegister'),
        { mode: 'view', isAccountOwner: this.isAccountOwner, institute: this.currentAssociation.institute })
    },
    responseToInvite (response) {
      this.doodleForm.errorMessage = ''
      this.executeAPI({
        url: '/api/guru-api/responseToInvite',
        data: {
          'instituteCode': this.currentAssociation.institute.code,
          'response': response
        },
        method: 'post',
        markCompleted: false,
        form: this.doodleForm,
        successAction: (data) => {
          eventBus.$emit(GURU_EVENTS.GURU_INIT)
          if (data.status === 'Rejected') {
            this.gotoGuruHome()
          }
        },
        jdaCategory: window.jda.CATEGORY.GURU,
        jdaEvent: 'guru-invite-' + response,
        jdaLabel: ''
      })
    },
    gotoGuruHome () {
      this.$router.push('/guru')
    }
  },
  computed: {
    isActive () {
      return this.currentAssociation.status === GURU_USER_STATUS.ACTIVE
    },
    isPendingAcceptance () {
      return this.currentAssociation.status === GURU_USER_STATUS.PENDING_USER_ACCEPTANCE
    },
    isRejected () {
      return this.currentAssociation.status === GURU_USER_STATUS.REJECT
    },
    isInActive () {
      return this.currentAssociation.status === GURU_USER_STATUS.IN_ACTIVE
    },
    isExited () {
      return this.currentAssociation.status === GURU_USER_STATUS.EXITED
    }
  }
}
</script>

<style lang="scss">
  .guru-ins-title {
    font-size: 1.2em;
    color: rgb(88, 88, 90);
    font-weight: bold;
    margin-bottom: 20px;
  }

  .guru-link {
    margin: auto;
    font-size: 1em;
    font-weight: 600;
    max-width: 350px;
  }

  .main-section {
    padding: 2%;
  }
</style>
